.whole-page-container {
    padding: 5%;
    padding-left: 10%;
    width: 100%;
    height: max-content;
    min-height: 100vh;
}

.ie-intro-container {
    margin-top: 5%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(400px, 100%), 1fr));
}

.ie-pic {
    width: 80%;
    height: auto;
    border-radius: 5%;
    box-shadow: 2px 4px 8px #f3f4cb;
    margin-bottom: 10%;
}

.ie-description-section {
    width: 100%;
    margin: auto;
}

.video {
    width: 90%;
    height: auto;
    border-radius: 3%;
    box-shadow: 2px 4px 8px #f3f4cb;
    margin-bottom: 10%;
}

.source-code-btn {
    font-family: 'Nunito', sans-serif;
    width: 110px;
    height: 35px;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    color: #000;
    background-color: #fff;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}

.source-code-btn:hover {
    background-color: #FFF36D;
    box-shadow: 0px 8px 13px #f2800653;
    color: #E69C43;
    transform: translateY(-7px);
}

@media screen and (max-width: 1000px) {
    .ie-pic.landing {
        padding-top: 5%;
    }

    .ie-description-section {
        padding-bottom: 10%;
    }
}