.chatbot {
    /* font-family: 'Nunito', sans-serif; */
    position: fixed;
    bottom: 3%;
    right: 3%;
    height: 30rem;
    width: 20rem;
    background-color: #F3F3F3;
    transition: all 0.3s ease-in-out;
    border-radius: 15px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.chatbot.closed {
    border-radius: 50%;
    background-color: #c4d36d;
    height: 5rem;
    width: 5rem;
    cursor: pointer;

    animation-name: circle-chatbot;
    animation-timing-function: linear;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.chatbot.closed:hover {
    opacity: 70%;
}

@keyframes circle-chatbot {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}

.email-section-btn {
    font-family: 'Nunito', sans-serif;
    width: 100px;
    height: 30px;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 450;
    color: #000;
    background-color: #fff;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    outline: none;
    transition: all 0.3s ease 0s;
}

.email-section-btn.close-pop-up-btn {
    position: fixed;
    right: 4%;
    bottom: 5%;
    transition: all 0.2s ease-in-out;
}

.sumbit-email-btn:hover,
.close-pop-up-btn:hover {
    background-color: #FFF36D;
    box-shadow: 0px 8px 13px #f2800653;
    color: #E69C43;
    transform: translateY(-7px);
}

.my-icon-section {
    width: 100%;
    height: 6rem;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: #c4d36d;
}

.chatbot-icon {
    width: auto;
    height: 4.5rem;
    display: block;
    margin: auto;
    clip-path: circle();
}

.chatbot-hi-text {
    display: block;
    margin: auto;
    text-align: center;
    color: graytext;
}

.message-icon {
    position: absolute;
    right: 50%;
    bottom: 50%;
    font-size: 2rem;
    transform: translate(50%, 50%);
}

.send-email-section {
    margin-top: 6%;
    margin-left: 10%;
    position: relative;
}

.email_label {
    font-size: small;
    font-weight: bold;
    color: #252222;
}

.email-input {
    border-radius: 5px;
    width: 15rem;
    height: 2rem;
    background: none;
    outline: none;
    border-color: #c4d36d;
    margin-top: 1%;
    margin-bottom: 3%;
}

.email-input.message {
    width: 15rem;
    height: 7rem;
}

.loader-icon {
    position: absolute;
    font-size: 3rem;
    opacity: 20%;
    right: 50%;
    bottom: 50%;
}

.email-sent-window {
    position: absolute;
    width: 225px;
    height: 55px;
    background-color: rgb(218, 233, 190);
    color: graytext;
    text-align: center;
    font-size: xx-small;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
}