@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap');

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    background-color: #FADCD2;

    font-family: 'Nunito', sans-serif;

    letter-spacing: 0.8px;
    word-spacing: 0.8px;

    /* background: radial-gradient(at 0% 10%, #edf5c1 10px, #AAD7EA 16%,
            #FADCD2 25%); */

    /* background-image: linear-gradient(to right,
            #edf5c1,
            #AAD7EA,
            #FADCD2); */

    background:
        radial-gradient(circle at 50% 0,
            #FADCD2,
            rgba(255, 0, 0, 0) 70.71%),
        radial-gradient(circle at 6.7% 75%,
            #AAD7EA,
            rgba(0, 0, 255, 0) 70.71%),
        radial-gradient(circle at 93.3% 75%,
            #edf5c1, rgba(0, 255, 0, 0) 70.71%) beige;

    background-repeat: no-repeat;
}


.nav {
    color: black;
    font-family: 'Nunito', sans-serif;
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 1rem 1rem;
    align-items: center;
}

.nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
}

.site-title {
    font-size: 2rem;
}

.nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: .25rem;
}

.nav li.active {
    cursor: pointer;
}

.nav li:hover {
    color: #c4d36d;
    transition: 0.5s;
}