@import "react-alice-carousel/lib/alice-carousel.css";

h1,
h2,
h3,
h4,
div {
    margin: 0;
}

.home-reuse-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(400px, 100%), 1fr));
    justify-content: center;
    margin: 2%;
}

.short-self-intro {
    height: 20rem;
    margin: 0 auto;
}

.profile-pic-container {
    position: relative;
    height: 20rem;
    width: 100%;
    margin: 0 auto;
}

.landing-cat {
    width: auto;
    height: 24%;
    position: absolute;
    bottom: 0%;
    left: 0%;
}

.landing-stand-read {
    width: auto;
    height: 80%;
    position: absolute;
    left: 12%;
    bottom: 2%;
}

.landing-ice-cream-bike {
    position: absolute;
    width: auto;
    height: 200px;
    left: 34%;
    bottom: 20%;
}

.landing-plant {
    position: absolute;
    width: auto;
    height: 90px;
    right: 23%;
    bottom: 0%;
}

.landing-warning-light {
    position: absolute;
    width: auto;
    height: 300px;
    right: 0%;
    bottom: 0%;
}

.short-self-intro {
    text-align: center;
    padding-top: 18%;
    padding-left: 18%;

}

@media screen and (max-width: 1000px) {
    .short-self-intro {
        margin: auto;
        padding-left: 0%;
    }
}

.home-name-text {
    font-weight: bolder;
}

.home-self-intro-text {
    padding-top: 5%;
}

.resume-button-container {
    padding-top: 5%;
    padding-bottom: 8%;
}

.button {
    font-family: 'Nunito', sans-serif;
    width: 120px;
    height: 35px;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 600;
    color: #000;
    background-color: #fff;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}

.button.project-btn:hover,
.button.resume-btn:hover {
    background-color: #FFF36D;
    box-shadow: 0px 8px 13px #f2800653;
    color: #E69C43;
    transform: translateY(-7px);
}

.button.project-btn:focus,
.button.resume-btn:focus {
    background-color: #FFF36D;
    box-shadow: 0px 8px 13px #f2800653;
    color: #E69C43;
    transform: translateY(-7px);
}

.waving-lines-section {
    width: 100%;
    height: fit-content;
    opacity: 80%;
    background-color: #FEFBEA;

    /* using: https://css-generators.com/wavy-shapes/ */
    --mask:
        radial-gradient(111.80px at 50% 150.00px, #000 99%, #0000 101%) calc(50% - 100px) 0/200px 100%,
        radial-gradient(111.80px at 50% -100px, #0000 99%, #000 101%) 50% 50px/200px 100% repeat-x;
    -webkit-mask: var(--mask);
    mask: var(--mask);

    border-bottom-left-radius: 15%;
    background-image: linear-gradient(to bottom, #FEFBEA, #FDF8E1);
}

.skillsets-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    justify-content: center;
    padding: 3%;
    align-items: flex-start;
}

.skillsets {
    background-color: #edf5c1;
    width: 70%;
    height: 85%;
    padding: 6%;
    margin: auto;
    border-radius: 5%;
    text-align: center;
    margin-bottom: 9%;
    box-shadow: 2px 2px 2px#f5f9e0;
}


.home-intro-skills {
    text-align: center;
}

.home-intro-skills span {
    margin-right: 2.2%;
    color: #E69C43;
}

.home-heading-text-container {
    text-align: center;
}

.home-heading-text {
    font-weight: bolder;
    padding-top: 9%;
}

@media screen and (max-width: 1000px) {
    .home-heading-text.skills {
        padding-top: 20%;
    }

    .waving-lines-section {
        border-bottom-left-radius: 10%;
    }
}

.introduce-projects {
    margin-top: 7%;
    text-align: center;
    font-weight: bolder;
}

.project-pic {
    width: max-content;
    height: auto;
    margin: 0 auto;
    padding-top: 10%;
}

.redirect-to-project {
    text-decoration: none;
    color: #2f2b2b;
}

.project-pic img {
    width: auto;
    height: 13rem;
    border-radius: 5%;
    box-shadow: 2px 4px 8px #f3f4cb;
}

.project-pic:hover img {
    cursor: pointer;
    opacity: 0.7;
    transition: 0.4s;
}

.alice-carousel__dots-item:hover {
    transition: 0.4s;
    background-color: #f3f4cb !important;
}

.alice-carousel__dots-item.__active:hover {
    transition: 0.4s;
    background-color: #f3f4cb !important;
}

.footer {
    width: 100%;
    height: 10rem;
    padding-top: 5%;
    margin-bottom: 5%;
    text-align: center;
}

.footer-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-item:hover {
    cursor: pointer;
    opacity: 0.6;
    transition: all 0.3s ease 0s;
}